import React, { useState, useEffect } from 'react';

const InferencesContext = React.createContext([{}, () => { }]);

const createBboxes = (areas, imageObject) => {
    var bboxesTemp = []
    for (const [key, value] of Object.entries(areas)) {
        var bboxTemp = {}
        bboxTemp.area = key
        bboxTemp.imageHeight = imageObject.height
        bboxTemp.imageWidth = imageObject.width
        bboxTemp.label = value["Fix required"]
        bboxTemp.x = value["Bbox coordinates"].x1 / imageObject.width
        bboxTemp.x2 = value["Bbox coordinates"].x2 / imageObject.width
        bboxTemp.y = value["Bbox coordinates"].y1 / imageObject.height
        bboxTemp.y2 = value["Bbox coordinates"].y2 / imageObject.height
        bboxesTemp.push(bboxTemp)
    }
    return bboxesTemp
};

const InferencesProvider = (props) => {
    const [inferences, setInferences] = useState({
        assessments: '',
        image: '',
        bboxes: []
    });

    useEffect(() => {
        if (inferences.image != '') {
            var imageObject = new Image();
            imageObject.onload = function () {
                var createdBboxes = createBboxes(inferences.assessments.Areas, imageObject)
                setInferences(
                    {
                        assessments: inferences.assessments,
                        image: inferences.image,
                        bboxes: createdBboxes
                    }
                )
            }
            imageObject.src = inferences.image;

        }
    }, [inferences.assessments])

    return (
        <InferencesContext.Provider value={[inferences, setInferences]}>
            {props.children}
        </InferencesContext.Provider>
    );
};

export { InferencesContext, InferencesProvider };