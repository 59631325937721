import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Footer from "components/Footer/Footer.jsx";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

// import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";

import image from "assets/img/bg8.jpg";

import Assess from "components/Suited/Assess.jsx";
import LinearDeterminate from "components/Suited/LinearDeterminate";

import { Grid, AppBar, Toolbar, Typography } from "@material-ui/core";
import App from "./App";
import "./styles.css";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SectionImage from "views/ComponentsPage/Sections/Suited/SectionImage.jsx";
import SectionAssessResults from "views/Suited/Sections/SectionAssessResults.jsx";
import { stubResults } from "components/Suited/stubResults.js";

import { InferencesProvider } from "../../InferencesContext";
import { InferencesContext } from "../../InferencesContext";

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import firebase from 'firebase/app'
import 'firebase/analytics'

class Components extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // change this back to upload when finished testing
      route: "upload",
      checked: [1],
      timer: 0,
      timerReset: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    this.timerID = setInterval(
      () => this.tick(),
      100
    );

    firebase.analytics().logEvent(this.state.route + '_page');

  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  resetTimer() {
    if (!this.state.timerReset) {
      this.setState({
        timer: 0
      });
      this.setState({
        timerReset: true
      });
    }
  }

  tick() {
    this.setState({
      timer: this.state.timer + 0.333
    });
  }

  onRouteChange = route => {
    this.setState({ route: route });
    firebase.analytics().logEvent(route + '_page');
  };

  render() {
    const { classes, ...rest } = this.props;
    const { route } = this.state;

    return (
      // <React.Fragment>
      <div>
        <InferencesProvider>
          <Header
            color="white"
            brand="Suited"
            links={<HeaderLinks dropdownHoverColor="dark" />}
            fixed
            changeColorOnScroll={{
              height: 100,
              color: "white"
            }}
            {...rest}
          />
          <div className={classes.pageHeader}>
            {(() => {
              switch (route) {
                case "upload":
                  return <Assess onRouteChange={this.onRouteChange} />;
                case "analysing":
                  this.resetTimer()
                  return (
                    <div className={classes.container}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                          <h1 className={classes.textCenter}>Assessing</h1>
                        </GridItem>
                      </GridContainer>
                      {/*I had to implement a local styles.css file here, which is different to the
              standard creative-tim approach of exporting with withStyles. It's inconsistent but 
              it works and doesn't seem to cause issues. */}
                      <GridContainer
                        className="flex-section"
                        justify="center"
                        spacing={40}
                      >
                        <GridItem
                          xs={12}
                          // sm={12}
                          md={9}
                          lg={9}
                          className={"flex-col-scroll-assess"}
                          // style={{ backgroundColor: "lightblue" }}
                          scrollToBottom={true}
                        >
                          <p><br></br></p>
                          <GridContainer
                            className="flex-section"
                            justify="center"
                            spacing={40}
                          >
                            <CircularProgress variant="static" value={this.state.timer} />
                          </GridContainer>
                          <GridContainer
                            className="flex-section"
                            justify="center"
                            spacing={40}
                          >
                            <p><br></br>This will take up to 30 seconds.</p>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </div>
                  )
                case "assess":
                  return (
                    <div className={classes.container}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                          <h1 className={classes.textCenter}>Assessment</h1>
                        </GridItem>
                      </GridContainer>
                      {/*I had to implement a local styles.css file here, which is different to the
                  standard creative-tim approach of exporting with withStyles. It's inconsistent but 
                  it works and doesn't seem to cause issues. */}
                      <GridContainer
                        className="flex-section"
                        justify="center"
                        spacing={40}
                      >
                        <GridItem
                          xs={5}
                          sm={4}
                          md={3}
                          lg={3}
                          className={"flex-col-scroll-pic"}
                        // style={{ backgroundColor: "pink" }}
                        >
                          <SectionImage />
                        </GridItem>
                        <GridItem
                          xs={12}
                          // sm={12}
                          md={9}
                          lg={9}
                          className={"flex-col-scroll-assess"}
                          // style={{ backgroundColor: "lightblue" }}
                          scrollToBottom={true}
                        >
                          <InferencesContext.Consumer>
                            {value =>
                              <SectionAssessResults assessments={value} />
                            }
                          </InferencesContext.Consumer>
                        </GridItem>
                      </GridContainer>
                    </div>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </InferencesProvider>
      </div >
      /* </React.Fragment> */
    );
  }
}

export default withStyles(signupPageStyle)(Components);
// export default Components;
