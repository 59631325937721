import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import MoneyOff from "@material-ui/icons/MoneyOff";
import FlashOn from "@material-ui/icons/FlashOn";
import School from "@material-ui/icons/School";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Why use us?</h2>
            <h5 className={classes.description}>
              We provide a simple, fast and free assessment of fit. Just provide a photo of yourself
              in your suit and we'll provide instant feedback.
              <br></br><br></br>
              "Fit is everything" - <a href="https://www.gq.com/gallery/how-a-suit-should-fit" style={{ color: "#275ccc" }}> GQ</a>
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Instant"
                description="The assessment uses cutting edge machine learning, so you get your results immediately."
                icon={FlashOn}
                iconColor="facebook"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Free"
                description="There is no charge and no need to register."
                icon={MoneyOff}
                iconColor="facebook"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Expert"
                description="The assessment uses GQ guidelines. See the FAQ for more information."
                icon={School}
                iconColor="facebook"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
