import React, { useContext } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import typographyStyle from "assets/jss/material-kit-pro-react/views/componentsSections/typographyStyle.jsx";
import image from "assets/img/assets-for-demo/presentationViewSectionComponent/afterAssessMock.png";
import ReactBoundingBox from "components/Suited/ReactBoundingBox/ReactBoundingBox.js";
import { InferencesContext } from "InferencesContext";

const SectionImage = (props) => {

  const [inferences, setInferences] = useContext(InferencesContext);

  return (
    <div id="images">
      <GridContainer justify="center">
        <GridItem xs={10}>
          <ReactBoundingBox />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(typographyStyle)(SectionImage);
