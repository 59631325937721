import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import CloudUpload from "@material-ui/icons/CloudUpload";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";
import School from "@material-ui/icons/School";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";
import demoGif from "assets/img/suited/example-pose/demo gif 10fps.gif";

class SectionHowItWorks extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>How it works</h2>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <img
                src={demoGif}
                alt="..."
                className={classes.imgRaised + " " + classes.imgFluid}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionHowItWorks);
