import React, { useContext } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Accordion from "components/Accordion/Accordion.jsx";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx";

import { generateDetail } from "views/Suited/Sections/GenerateDetail.js"
import { InferencesContext } from "InferencesContext";

import firebase from 'firebase/app'
import 'firebase/analytics'

const SectionAssessResults = (props) => {

  const [inferences, setInferences] = useContext(InferencesContext);

  const { classes } = props;

  return (
    < div >
      <div className={classes.services}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Accordion
              active={1}
              collapses={[
                {
                  title: "Detailed assessment",
                  content: generateDetail(classes, Object.entries(inferences.assessments.Areas))
                },
                {
                  title: "Summary",
                  content: (
                    <GridContainer>
                      <GridItem>
                        <p>
                          {inferences.assessments.General.Assessment}
                        </p>
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridContainer justify="center">
            <GridItem GridItem xs={12}>
              {" "}
              {inferences.assessments.General['Fix required'] == "tailor" ? <Button
                color="facebook"
                size="lg"
                href="https://www.google.com/search?q=tailors+near+me"
                target="_blank"
                rel="noopener noreferrer"
                round
                onClick={() => { firebase.analytics().logEvent('view_tailors'); }}
              >
                Find a tailor
                </Button> : <Button
                  color="facebook"
                  size="lg"
                  href="https://www.hockerty.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  round
                  onClick={() => { firebase.analytics().logEvent('view_made_to_measure_suits'); }}
                >
                  View made-to-measure suits
                </Button>}


            </GridItem>
          </GridContainer>
        </GridContainer>
      </div>
    </div >
  );
}


export default withStyles(style)(SectionAssessResults);
