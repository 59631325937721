import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AccordionFAQAccordion from "components/Accordion/AccordionFAQAccordion.jsx";

import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx";

import fitBeforeAfter from "assets/img/suited/fitBeforeAfter.jpg";


function CollapsibleFaqContent(props) {
  const { classes } = props;
  return (
    <div>
      <div className={classes.services}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <AccordionFAQAccordion
              collapses={[
                {
                  title: "How important is fit and what difference does it make?",
                  content: (
                    <GridContainer>
                      <GridItem>
                        <p>
                          <a href="https://www.gq.com/gallery/how-a-suit-should-fit" style={{ color: "#275ccc" }}> GQ</a> say that "fit is everything". <a href={fitBeforeAfter} style={{ color: "#275ccc" }}>Here's </a>
                          an example of a suit after improvements were made to its fit.
                        </p>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Isn't fit subjective? What do you base the assessment on?",
                  content: (
                    <GridContainer>
                      <GridItem>
                        <p>
                          Fit is subjective, but there is a widely agreed upon set of guidelines. We follow <a href="https://www.gq.com/gallery/how-a-suit-should-fit" style={{ color: "#275ccc" }}> GQ's</a> but you can
                          see how consistent these are across various guides:
                          <ul>
                            <li><a href="https://thehelmclothing.com/blogs/our-thoughts/14446353-top-10-signs-you-re-in-a-poor-fitting-suit" style={{ color: "#275ccc" }}> The Helm Clothing</a></li>
                            <li><a href="https://www.themodestman.com/alterations-101-mens-suit-jackets-and-blazers/" style={{ color: "#275ccc" }}> The Modest Man</a></li>
                            <li><a href="https://imgur.com/gallery/Sl67c#Gl7Y2Y9" style={{ color: "#275ccc" }}> Samuel Windsor</a></li>
                            <li><a href="https://theblacktux.com/blog/how-should-a-suit-fit/" style={{ color: "#275ccc" }}> The Black Tux</a></li>
                            <li><a href="https://www.realmenrealstyle.com/visual-suit-fit-guide/" style={{ color: "#275ccc" }}> Real Men Real Style</a></li>
                            <li><a href="https://www.thedarkknot.com/blogs/suitupdressup/how-should-a-suit-fit" style={{ color: "#275ccc" }}> The Dark Knot</a></li>
                          </ul>
                        </p>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Does the service work for anyone?",
                  content: (
                    <GridContainer>
                      <GridItem>
                        <p>
                          Unfortunately it is only available for men at the
                          moment.
                        </p>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "Do you collect information while I use the site?",
                  content: (
                    <GridContainer>
                      <GridItem>
                        <p>
                          Google Analytics collects data on how the site is used. LogRocket is used to replay users' interactions with the site. Both are used to improve the service.
                        </p>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  title: "What are your terms and conditions?",
                  content: (
                    <GridContainer>
                      <GridItem>
                        <p>You agree to/accept:</p>
                        <p>1) This is still in development. Some recommendations will be incorrect. For example, an area may be assessed as fitting well when, in fact, it isn't.</p>
                        <p>2) Submitted photos are needed to provide the service, and will only be kept and used for that purpose. </p>
                        <p>3) Not upload personally identifiable photos. The photo should be from the neck down.</p>
                      </GridItem>
                    </GridContainer>
                  )
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(style)(CollapsibleFaqContent);
