import rightShoulder from "assets/img/right shoulder.png";
import leftShoulder from "assets/img/left shoulder.png";

const SUIT_POINTS = {
  rightShoulder: "Right shoulder",
  leftShoulder: "Left shoulder"
};
Object.freeze(SUIT_POINTS);

const SUIT_AVATARS = {
  rightShoulder,
  leftShoulder
};
Object.freeze(SUIT_AVATARS);

const ASSESSMENT_VALUES = {
  correct: "correct",
  tooNarrow: null,
  tooWide: null
};
Object.freeze(ASSESSMENT_VALUES);

const stubResults = [
  {
    suitPoint: SUIT_POINTS.rightShoulder,
    suitAvatar: SUIT_AVATARS.rightShoulder,
    assessment: ASSESSMENT_VALUES.tooWide,
    text: "This is too wide."
  },
  {
    suitPoint: SUIT_POINTS.leftShoulder,
    suitAvatar: SUIT_AVATARS.leftShoulder,
    assessment: ASSESSMENT_VALUES.correct,
    text: "This is correct."
  }
];

export { stubResults };
