export const FixRequired = {
    NEWSUIT: "newSuit",
    NONE: "none",
    TAILOR: "tailor",
    UNKNOWN: "unknown"
};

export const Colours = {
    GREEN: 'hsl(120, 100%, 37%)',
    RED: 'hsl(356, 100%, 37%)'
}

export const FixToBoxColours = {
    [FixRequired.NEWSUIT]: Colours.RED,
    [FixRequired.NONE]: Colours.GREEN,
    [FixRequired.TAILOR]: Colours.RED,
    [FixRequired.UNKNOWN]: Colours.RED
};

// var FixRequired = FixRequired.newSuit;