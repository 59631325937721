import React, { useContext } from "react";
// @material-ui/core components
import DirectionsWalk from "@material-ui/icons/DirectionsWalk";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import Media from "components/Media/Media.jsx";
import leftSleeve from "assets/img/left_sleeve.png";
import jacketLength from "assets/img/jacket length.png";
import leftShoulder from "assets/img/left shoulder.png";
import leftTrouser from "assets/img/left trouser.png";
import rightShoulder from "assets/img/right shoulder.png";
import rightSleeve from "assets/img/right sleeve.png";
import rightTrouser from "assets/img/right trouser.png";
import waist from "assets/img/waist.png";

import { red } from "@material-ui/core/colors";
import { green } from "@material-ui/core/colors";
import { stubResults } from "components/Suited/stubResults.js";

import { InferencesContext } from "InferencesContext";
import MiniGuide from "components/Suited/MiniGuide.jsx";


function generateGridItemMedia(element, classes) {

  const [inferences, setInferences] = useContext(InferencesContext);

  return (
    <GridItem>
      <Media
        avatar={inferences.image}
        bboxes={inferences.bboxes}
        area={element[0]}
        title={
          <span>
            {element[0]}
            <small />
          </span>
        }
        body={
          <span>
            <p>
              {element[1].Assessment}
              <div>
                <Tooltip
                  id="tooltip"
                  title="See a fit guide for this area."
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="facebook"
                    simple
                    size="sm"
                    className={classes.floatRight}
                  >
                    <MiniGuide area={element[0]}></MiniGuide>
                  </Button>

                </Tooltip>

              </div>
            </p>
          </span>
        }
        backgroundColour={element[1]["Fix required"] == "none" ? green : red}
      />
    </GridItem>
  );
}

function generateDetail(classes, inferences) {
  return (
    <GridContainer>
      {
        inferences.map(function (element) {
          return generateGridItemMedia(element, classes)
        })
      }
    </GridContainer >
  );
}

export { generateDetail };
