import React, { useState, useContext } from 'react';
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import Button from "components/CustomButtons/Button.jsx";
import TermsAndConditionsButton from "components/Suited/TermsAndConditionsButton.jsx";

import defaultImage from "assets/img/image_placeholder.png";
import uploadingImage from "assets/img/uploading_image_placeholder.png";
import exampleNewSuit from "assets/img/suited/example-pose/example - take waist in - tailor - enlarged without head2.jpg";

import { InferencesContext } from "../../InferencesContext";

import ExamplePose from "components/Suited/ExamplePose.jsx";

import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/analytics'

import uuidv4 from 'uuid/v4'

import loadImage from 'blueimp-load-image';

const ImageUpload = (props) => {

  const fileInput = React.useRef(null)
  const [file, setFile] = useState(null)
  const [userHasSubmittedFile, setUserHasSubmittedFile] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage)
  const [inferences, setInferences] = useContext(InferencesContext);
  let assessServer = ''

  const handleClick = e => {
    fileInput.current.click();
  }

  const handleUseExample = () => {
    setUserHasSubmittedFile(true)
    handleImageChange()
  }

  const handleRemove = e => {
    setUserHasSubmittedFile(false)
    setFile(null)
    setImagePreviewUrl(defaultImage)
  }

  const getAssessEnvAddress = e => {
    if (process.env.NODE_ENV == 'development') {
      assessServer = process.env.REACT_APP_ASSESS_ADDRESS_DEV
    } else if (process.env.NODE_ENV == 'production') {
      assessServer = process.env.REACT_APP_ASSESS_ADDRESS_PROD
    }
    return assessServer
  }

  const analyze = (file, base64Image) => {

    var xhr = new XMLHttpRequest();
    var loc = window.location;
    // xhr.open("POST", `${loc.protocol}//${loc.hostname}:5999/analyze`, true);
    xhr.open("POST", getAssessEnvAddress(), true);
    xhr.onerror = function () {
      alert(xhr.responseText);
    };

    xhr.onload = function (e) {
      if (this.readyState === 4) {
        var response = JSON.parse(e.target.responseText);
        // This seems to only be reflected after analyze is complete.
        // I presume the javascript engine puts the setInferences execution in the queue
        // after analyze. Just worth being aware of, not necessarily an issue.

        // analyse can't clone from inferences.image, since it is empty when xhr began
        // i.e. even though xhr.onload will run after image has been updatd in the Context,
        // the version of image xhr.onload has is empty.
        var secondNewImageAndAssessments = {
          assessments: inferences.assessments,
          image: base64Image,
          bboxes: inferences.bboxes
        }
        secondNewImageAndAssessments.assessments = response
        setInferences(secondNewImageAndAssessments)
        props.onRouteChange('assess')
      }

    };
    var fileData = new FormData();
    fileData.append("file", file);
    xhr.send(fileData);
  };


  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //load src and convert to a File instance object
  //work for any type of src, not only image src.
  //return a promise that resolves with a File instance
  function srcToFile(src, fileName, mimeType) {
    return (fetch(src)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], fileName, { type: mimeType }); })
    );
  }

  const loadImageHelper = file => {
    loadImage(
      file,
      function (img) {
        let reader = new FileReader();
        reader.onloadend = () => {
          const dataURL = img.toDataURL('image/webp', 1);
          setImagePreviewUrl(dataURL)
          setUserHasSubmittedFile(true)
          firebase.analytics().logEvent('photo_uploaded_page');
        };
        reader.readAsDataURL(file);
      },
      {
        maxWidth: 800,
        maxHeight: 400,
        minWidth: 100,
        minHeight: 50,
        orientation: true,
      }
    );
  }

  const handleImageChange = e => {
    // e.preventDefault();

    // i.e. the user selected 'use example', so no file upload event exists
    // as it would if the user had uploaded a file
    if (e == undefined) {
      srcToFile(exampleNewSuit, "example.jpg", "image/jpeg")
        .then(example => {
          let file = example
          setFile(file)
          // Output an img with the correct orientation.
          // Then update the preview URL with a small size image for:
          // Improved load time (possibly)
          loadImageHelper(file)
        }
        )
    } else {
      let file = e.target.files[0]
      setFile(e.target.files[0])
      // Output an img with the correct orientation.
      // Then update the preview URL with a small size image for:
      // Improved load time (possibly)
      loadImageHelper(file)
    }
  }

  function uploadFile(file) {
    const storageRef = firebase.storage().ref();
    const imageName = uuidv4()

    var filenameextension = file.name.replace(/^.*[\\\/]/, '');
    var ext = filenameextension.split('.').pop();
    let storagePath

    if (process.env.NODE_ENV == 'development') {
      storagePath = process.env.REACT_APP_STORAGE_PATH_DEV
    } else if (process.env.NODE_ENV == 'production') {
      storagePath = process.env.REACT_APP_STORAGE_PATH_PROD
    }

    const imgRef = storageRef.child(storagePath + imageName + '.' + ext);

    imgRef.put(file)
  }

  const handleAssess = e => {

    props.onRouteChange('analysing')



    if (file.name == "example.jpg") {
      convertAndAssess(file, dataURItoBlob, inferences, setInferences, props, returnApproachToTake(false));
    } else {
      // Output an img with the correct orientation.
      // Then update the file with the (full size) file that will 
      // be sent to the server
      convertAndAssess(file, dataURItoBlob, inferences, setInferences, props, returnApproachToTake(true));
    }
  }

  const convertAndAssess = (file, dataURItoBlob, inferences, setInferences, props, returnApproachToTake) => {
    loadImage(file, function (img) {
      let reader = new FileReader();
      reader.onloadend = () => {
        // Convert canvas image to Base64
        var dataURI = img.toDataURL('image/jpeg');
        // Convert Base64 image to binary
        var blob = dataURItoBlob(dataURI);
        // Convert blob to file
        blob.lastModifiedDate = new Date();
        blob.name = "file-for-server.png";
        let compressedFile = blob;
        let imagePromise = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
        imagePromise
          .then(async (base64Image) => {
            // Clone rather than update the object, since a clone triggers a React re-render
            var newImageAndAssessments = {
              assessments: inferences.assessments,
              // Present the user with a compressed image for performance
              image: inferences.image,
              bboxes: [{}]
            };
            newImageAndAssessments.image = base64Image;
            return newImageAndAssessments;
          })
          .then(async (newImageAndAssessments) => {
            // To ensure it only resolves when setInferences is complete, you'd need to hand
            // a callback to setInferences. Will async/await fix this?
            setInferences(newImageAndAssessments);
            return newImageAndAssessments.image;
          })
          .then(returnApproachToTake);
      };
      reader.readAsDataURL(file);
    }, {
      orientation: true,
    });
  }

  const returnApproachToTake = userProvidedAphoto => {
    if (userProvidedAphoto) {
      return (
        (base64Image => {
          // Analyse the raw image
          analyze(file, base64Image);
          uploadFile(file)
          firebase.analytics().logEvent('real_photo_assessed');
        })
      )
    } else {
      return (
        (base64Image => {
          var secondNewImageAndAssessments = {
            assessments: inferences.assessments,
            image: base64Image,
            bboxes: inferences.bboxes
          };
          secondNewImageAndAssessments.assessments = { "Areas": { "Left shoulder": { "Assessment": "This shoulder's fit looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 314, "x2": 441, "y1": 85, "y2": 212 } }, "Right shoulder": { "Assessment": "This shoulder's fit looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 85, "x2": 215, "y1": 113, "y2": 243 } }, "Torso": { "Assessment": "The waist looks too big. Try having it taken in by a tailor. This will cost around $40.", "Cost": 40, "Fix required": "tailor", "Bbox coordinates": { "x1": 100, "x2": 418, "y1": 157, "y2": 475 } }, "Left sleeve": { "Assessment": "The sleeve fit looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 351, "x2": 450, "y1": 412, "y2": 511 } }, "Right sleeve": { "Assessment": "The sleeve fit looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 86, "x2": 167, "y1": 428, "y2": 509 } }, "Jacket length": { "Assessment": "The jacket length looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 126, "x2": 291, "y1": 386, "y2": 551 } }, "Left trouser": { "Assessment": "This trouser's length looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 215, "x2": 430, "y1": 876, "y2": 1091 } }, "Right trouser": { "Assessment": "This trouser's length looks good.", "Cost": 0, "Fix required": "none", "Bbox coordinates": { "x1": 116, "x2": 312, "y1": 894, "y2": 1090 } } }, "General": { "Fix required": "tailor", "Cost": 40, "Assessment": "You can improve the fit of your suit by having a tailor adjust the fit. The total cost will be approximately $40. Find a tailor near you by clicking below." } };
          setInferences(secondNewImageAndAssessments);
          props.onRouteChange('assess');
          firebase.analytics().logEvent('example_assessed');
        })
      )
    }
  }

  // If user loads their OS window to upload a file but cancels then you'll get the following error:
  // TypeError: Failed to execute 'readAsDataURL' on 'FileReader': parameter 1 is not of type 'Blob'.
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {/* The Button was originally enclose by this line and more below, 
      which I've omitted for now */}
        {userHasSubmittedFile === false ? (
          <span>
            <GridContainer justify="center">
              <GridItem xs={6}>
                <Button
                  {...props.removeButtonProps}
                  onClick={handleUseExample}
                >
                  Use Example
                 </Button>
              </GridItem>
              <GridItem xs={6}>
                {avatar ? <br /> : null}
                <Button {...props.assessButtonProps} onClick={handleClick}>
                  {avatar ? "Add Photo" : "Provide photo"}
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={10} sm={10} md={10}>
                <ExamplePose></ExamplePose>
              </GridItem>
            </GridContainer>
          </span>
        ) : (
            <span>
              <TermsAndConditionsButton></TermsAndConditionsButton>
              <GridContainer justify="center">
                <GridItem xs={4}>
                  <Button
                    {...props.removeButtonProps}
                    onClick={handleRemove}
                  >
                    Remove
                 </Button>
                </GridItem>
                <GridItem xs={4}>

                  {avatar ? <br /> : null}
                  <Button
                    {...props.assessButtonProps}
                    onClick={() => handleAssess()}
                  >
                    Assess
              </Button>
                </GridItem>
              </GridContainer>

            </span>
          )
        }
      </div>
    </div >
  )
};

export default ImageUpload;




