import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.3.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.jsx";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.jsx";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.jsx";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.jsx";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.jsx";
import EcommercePage from "views/EcommercePage/EcommercePage.jsx";
import Faq from "views/Suited/Faq.jsx";

import LandingPage from "views/Suited/LandingPage.jsx";
import LoginPage from "views/LoginPage/LoginPage.jsx";
import PricingPage from "views/PricingPage/PricingPage.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";
import ProductPage from "views/ProductPage/ProductPage.jsx";
import SectionsPage from "views/SectionsPage/SectionsPage.jsx";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.jsx";
import AssessFlowPage from "views/Suited/AssessFlowPage.jsx";
import ErrorPage from "views/ErrorPage/ErrorPage.jsx";
import LogRocket from 'logrocket';
import firebase from 'firebase/app'
import 'firebase/analytics'

// Session Replay
const returnEnvKeyForLogRocket = () => {
  if (process.env.NODE_ENV == 'development') {
    return 'mh5eam/suited'
  } else if (process.env.NODE_ENV == 'production') {
    return 'mh5eam/prod-suited'
  }
}
LogRocket.init(returnEnvKeyForLogRocket());

const returnFirebaseConfig = () => {
  if (process.env.NODE_ENV == 'development') {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY_DEV,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
      databaseURL: process.env.REACT_APP_DATABASE_URL_DEV,
      projectId: process.env.REACT_APP_PROJECT_ID_DEV,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
      appId: process.env.REACT_APP_APP_ID_DEV,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV
    };
    return firebaseConfig
  } else if (process.env.NODE_ENV == 'production') {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY_PROD,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
      databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
      projectId: process.env.REACT_APP_PROJECT_ID_PROD,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
      appId: process.env.REACT_APP_APP_ID_PROD,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID_PROD
    };
    return firebaseConfig
  }
}

// Initialize Firebase
firebase.initializeApp(returnFirebaseConfig());
firebase.analytics();

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/blog-post" component={BlogPostPage} />
      <Route path="/blog-posts" component={BlogPostsPage} />
      <Route path="/components" component={ComponentsPage} />
      <Route path="/contact-us" component={ContactUsPage} />
      <Route path="/ecommerce-page" component={EcommercePage} />
      <Route path="/FAQ" component={Faq} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/pricing" component={PricingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/product-page" component={ProductPage} />
      <Route path="/sections" component={SectionsPage} />
      <Route path="/shopping-cart-page" component={ShoppingCartPage} />
      <Route path="/assessflow" component={AssessFlowPage} />
      <Route path="/error-page" component={ErrorPage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
