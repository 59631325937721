import React, { Fragment } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import * as Scroll from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

const style = {
  grid: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px"
    /* flexBasis: "auto" */
  }
};

class GridItem extends React.Component {
  constructor(props) {
    super(props);
    this.myDivToFocus = React.createRef();
  }

  componentDidMount() {
    if (this.props.scrollToBottom) {
      setTimeout(
        () =>
          this.myDivToFocus.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest"
          }),
        700
      );
    }
  }

  render() {
    const { ...props } = this.props;
    const { classes, children, className, ...rest } = props;

    if (this.props.scrollToBottom) {
      return (
          <Grid item {...rest} className={classes.grid + " " + className}>
            {children}
            <div ref={this.myDivToFocus}></div>
          </Grid>
      );
    } else {
      return (
        <Grid item {...rest} className={classes.grid + " " + className}>
          {children}
        </Grid>
      );
    }
  }
}

export default withStyles(style)(GridItem);
