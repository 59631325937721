import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components

import mediaStyle from "assets/jss/material-kit-pro-react/components/mediaStyle.jsx";
import { red } from "@material-ui/core/colors";
import { green } from "@material-ui/core/colors";

const Media = (props) => {

  // Dummy data for detailed crops
  var cropHeight = 20
  var cropWidth = 30
  var xOffset = 100
  var yOffset = 100
  var centerOffset = 100
  var backgroundSize = 100000

  const [divStyle, setDivStyle] = useState(
    {
      backgroundImage: 'url(' + props.avatar + ')',
      height: `${cropHeight}px`,
      width: `${cropWidth}px`,
      backgroundPosition: `${xOffset}px ${yOffset +
        centerOffset}px`,
      backgroundSize: `${backgroundSize}%`
    }
  )

  // Real data for detailed crops
  useEffect(() => {
    // There are times bboxes is not handed through because it doesn't exist
    // This is a code smell, as really an empty array should be passed every 
    // time, rather than it just not existing.
    if (typeof props.bboxes != "undefined") {
      if (Object.entries(props.bboxes).length != 0) {
        if (Object.entries(props.bboxes[0]).length != 0) {
          for (const [key, coords] of Object.entries(props.bboxes)) {
            if (coords.area == props.area) {
              const imageHeight = props.bboxes[0].imageHeight
              const imageWidth = props.bboxes[0].imageWidth

              const cropHeight = 65
              const cropWidth = 65

              const scale = cropWidth / ((coords.x2 - coords.x) * imageWidth)
              const objHeight = scale * (coords.y2 - coords.y) * imageHeight
              const centerOffset = (cropHeight - objHeight) / 2
              const xOffset = -coords.x * scale * imageWidth
              const yOffset = -coords.y * scale * imageHeight
              const backgroundSize = scale * (100 / cropWidth) * imageWidth
              setDivStyle({
                backgroundImage: 'url(' + props.avatar + ')',
                height: `${cropHeight}px`,
                width: `${cropWidth}px`,
                backgroundPosition: `${xOffset}px ${yOffset +
                  centerOffset}px`,
                backgroundSize: `${backgroundSize}%`
              })
            }
          }
        }
      }
    }
  }, [props.bboxes])

  const {
    classes,
    avatarLink,
    avatar,
    avatarAlt,
    title,
    body,
    footer,
    innerMedias,
    backgroundColour,
    ...rest
  } = props;
  if (backgroundColour == red) {
    return (
      <div {...rest} className={classes.media}>
        <a href={avatarLink} className={classes.mediaLink}>
          <div className={classes.mediaAvatarRed}>
            <div style={divStyle}></div>;
          </div>
        </a>
        <div className={classes.mediaBody}>
          {title !== undefined ? (
            <h4 className={classes.mediaHeading}>{title}</h4>
          ) : null}
          {body}
          <div className={classes.mediaFooter}>{footer}</div>
          {innerMedias !== undefined
            ? innerMedias.map((prop, key) => {
              return prop;
            })
            : null}
        </div>
      </div>
    );
  } else if (backgroundColour == green) {
    return (
      <div {...rest} className={classes.media}>
        <a href={avatarLink} className={classes.mediaLink}>
          <div className={classes.mediaAvatarGreen}>
            <div style={divStyle}></div>;
          </div>
        </a>
        <div className={classes.mediaBody}>
          {title !== undefined ? (
            <h4 className={classes.mediaHeading}>{title}</h4>
          ) : null}
          {body}
          <div className={classes.mediaFooter}>{footer}</div>
          {innerMedias !== undefined
            ? innerMedias.map((prop, key) => {
              return prop;
            })
            : null}
        </div>
      </div>
    );
  } else {
    return (
      <div {...rest} className={classes.media}>
        <a href={avatarLink} className={classes.mediaLink}>
          <div className={classes.mediaAvatar}>
            <img src={avatar} alt={avatarAlt} />
          </div>
        </a>
        <div className={classes.mediaBody}>
          {title !== undefined ? (
            <h4 className={classes.mediaHeading}>{title}</h4>
          ) : null}
          {body}
          <div className={classes.mediaFooter}>{footer}</div>
          {innerMedias !== undefined
            ? innerMedias.map((prop, key) => {
              return prop;
            })
            : null}
        </div>
      </div>
    );
  }
}

Media.defaultProps = {
  avatarLink: "#",
  avatarAlt: "..."
};

Media.propTypes = {
  avatarLink: PropTypes.string,
  avatar: PropTypes.string,
  avatarAlt: PropTypes.string,
  title: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  innerMedias: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(mediaStyle)(Media);
