import React, { Component, useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Canvas, { MOVE, BOX } from './Canvas'
import { InferencesContext } from 'InferencesContext';
import { FixRequired, Colours, FixToBoxColours } from './FitToColourEnum'
import './styles.css'

const ReactBoundingBox = (props) => {

  const _ = require("lodash")

  const [inferences, setInferences] = useContext(InferencesContext);

  const [state, defineState] = useState(
    {
      image: '',
      bboxes: [],
      label: '',
      labels: ['cat', 'face', 'ear', 'eye', 'paw'],
      mode: BOX
    }
  )

  useEffect(() => {
    defineState(state => {
      var deep = _.cloneDeep(state);
      deep.image = inferences.image
      deep.bboxes = state.bboxes
      return deep
    })
  }, [inferences.image])

  useEffect(() => {
    // There is a point when an object exists but is empty. The box should
    // not be drawn at this point. I THINK it happens because of the async
    // imageObject.onload in InferencesContext
    if (Object.entries(inferences.bboxes).length != 0) {
      if (Object.entries(inferences.bboxes[0]).length != 0) {
        for (const [key, coords] of Object.entries(inferences.bboxes)) {
          handleDrawStarted(coords)
        }
      }
    }


    defineState(state => {
      var deep = _.cloneDeep(state);
      deep.image = inferences.image
      deep.bboxes = state.bboxes
      return deep
    })

  }, [inferences.bboxes[0]])

  const handleModeChanged = (e) => {
    defineState({
      mode: e.target.value
    })
  }

  const handleLabelChanged = (e) => {
    defineState({
      label: e.target.value
    })
  }

  const handleCoordinatesChanged = (bbox, index) => {
    defineState(prevState => {
      const bboxes = [...prevState.bboxes]

      bboxes[index] = bbox
      return { bboxes: bboxes }
    })
  }

  const handleDrawStarted = bbox => {
    defineState(state => {
      var deep = _.cloneDeep(state)
      bbox.color = FixToBoxColours[bbox.label]
      const bboxes = [bbox, ...state.bboxes]
      deep.bboxes = bboxes
      return deep
    })
  }

  const handleFileSelect = e => {
    var file = e.target.files[0]
    var reader = new FileReader()
    reader.onload = () => { }
    reader.readAsDataURL(file)
  }

  return (
    <div style={{ display: 'flex' }}>
      <Canvas
        mode={state.mode}
        bboxes={state.bboxes}
        image={state.image}
        onDrawStarted={handleDrawStarted}
        onCoordinatesChanged={handleCoordinatesChanged}
      />
      {/* <div>
          <div>{`Mode: ${this.state.mode}`}</div>
          <button value={MOVE} onClick={this.handleModeChanged}>
            Move
          </button>
          <button value={BOX} onClick={this.handleModeChanged}>
            Draw
          </button>
          <div>{`Label: ${this.state.label}`}</div> */}
      {/* {this.state.labels.map(label => (
            <button value={label} onClick={this.handleLabelChanged}>
              {label}
            </button>
          ))}
          {this.state.bboxes
            .sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase())
            .map(box => {
              const imageHeight = 700
              const imageWidth = 1120

              const cropHeight = 20
              const cropWidth = 30

              const scale = cropWidth / ((box.x2 - box.x) * imageWidth)
              const objHeight = scale
              const centerOffset = (cropHeight - objHeight)
              const xOffset = -box.x * scale * imageWidth
              const yOffset = -box.y * scale * imageHeight
              const backgroundSize = scale * (100 / cropWidth)

              return (
                <div>
                  <div
                    style={{
                      backgroundImage: `url(${this.state.image})`,
                      height: `${cropHeight}px`,
                      width: `${cropWidth}px`,
                      backgroundPosition: `${xOffset}px ${yOffset +
                        centerOffset}px`,
                      backgroundSize: `${backgroundSize}%`
                    }}
                  />
                  <div>{box.label}</div>
                </div>
              )
            })} */}
      {/* </div> */}
    </div>
  )
}

export default ReactBoundingBox;