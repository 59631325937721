import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import accordionStyle from "assets/jss/material-kit-pro-react/components/accordionStyle.jsx";

import firebase from 'firebase/app'
import 'firebase/analytics'

class AccordionFAQAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      single: false
    };
  }

  componentWillMount() {
    // am facut array din numar ca metoda .find sa functioneze indiferent de ce se intampla.
    if (this.state.active.length === undefined) {
      this.setState({
        active: [this.state.active],
        single: true
      });
    }
  }

  handleChange = panel => (event, expanded) => {
    let newArray;

    if (this.state.single) {
      if (this.state.active[0] === panel) {
        newArray = [];
      } else {
        newArray = [panel];
      }
    } else {
      if (this.state.active.indexOf(panel) === -1) {
        newArray = [...this.state.active, panel];
      } else {
        newArray = [...this.state.active];
        newArray.splice(this.state.active.indexOf(panel), 1);
      }
    }
    this.setState({
      active: newArray
    });
    if (panel == 0 && expanded) {
      firebase.analytics().logEvent('faq_fit_importance_expanded');
    } else if (panel == 0 && !expanded) {
      firebase.analytics().logEvent('faq_fit_importance_closed');
    } else if (panel == 1 && expanded) {
      firebase.analytics().logEvent('faq_fit_subjective_expanded');
    } else if (panel == 1 && !expanded) {
      firebase.analytics().logEvent('faq_fit_subjective_closed');
    } else if (panel == 2 && expanded) {
      firebase.analytics().logEvent('faq_service_anyone_expanded');
    } else if (panel == 2 && !expanded) {
      firebase.analytics().logEvent('faq_service_anyone_closed');
    } else if (panel == 3 && expanded) {
      firebase.analytics().logEvent('faq_money_expanded');
    } else if (panel == 3 && !expanded) {
      firebase.analytics().logEvent('faq_money_closed');
    } else if (panel == 4 && expanded) {
      firebase.analytics().logEvent('faq_info_collection_expanded');
    } else if (panel == 4 && !expanded) {
      firebase.analytics().logEvent('faq_info_collection_closed');
    } else if (panel == 5 && expanded) {
      firebase.analytics().logEvent('faq_terms_expanded');
    } else if (panel == 5 && !expanded) {
      firebase.analytics().logEvent('faq_terms_closed');
    }
  };
  render() {
    const { classes, collapses, activeColor } = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={
                this.state.active === key ||
                this.state.active.indexOf(key) !== -1
              }
              onChange={this.handleChange(key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                classes={{
                  root: `${classes.expansionPanelSummary} ${
                    classes[activeColor + "ExpansionPanelSummary"]
                    }`,
                  expanded: `${classes.expansionPanelSummaryExpaned} ${
                    classes[activeColor + "ExpansionPanelSummaryExpaned"]
                    }`,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon
                }}
              >
                <h3 >{prop.title}</h3>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {prop.content}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

AccordionFAQAccordion.defaultProps = {
  active: -1,
  activeColor: "primary"
};

AccordionFAQAccordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired,
  activeColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ])
};

export default withStyles(accordionStyle)(AccordionFAQAccordion);
