import React from "react";
// components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.jsx";



class Components extends React.Component {
  render() {
    const { classes, onRouteChange } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <h1 className={classes.textCenter}>Upload</h1>
            <GridContainer justify="center">
              <ImageUpload
                addButtonProps={{ round: true }}
                assessButtonProps={{
                  round: true,
                  color: "facebook"
                }}
                removeButtonProps={{ round: true }}
                onRouteChange={onRouteChange}
              />
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Components);
